
export const AppRoutes = {
    BASE: '/',
    Login: '/login',
    News: '/news',
    Partnerships: '/partnerships',
    BecomeExpert: '/become-expert',
    BecomeTrainer:'/become-trainer',
    RequestSupport:'/request-support',
    About: '/about',
    WhereWeWork: '/about/where-we-work',
    Projects: '/about/projects',
    Missions: '/about/missions',
    // GetInvolvedWhatWeDo: '/about/get-involved',
    ICARS:'/partnerships/ICARS',
    ISAC:'/partnerships/ISAC',
    ESCMID: '/partnerships/ESCMID',
    Others: '/partnerships/others',
    GetInvolvedPartnerships: '/partnerships/get-involved',
    WhatIsSpiceExpert: '/become-expert/what-is-spice-expert',
    // TermsAndConditionsExpert: '/become-expert/terms-and-conditions',
    ExpertStories: '/become-expert/meet-our-experts',
    ExpertApply: '/become-expert/apply',//,'https://www.aanmelder.nl/137902/subscribe',
    ExpertApplyEdit: '/become-expert/edit',
    WhatIsAMSTrainer: '/become-trainer/what-is-ams-trainer',
    // TermsAndConditionsTrainer: '/become-trainer/terms-and-conditions',
    OurTrainers: '/become-trainer/our-trainers',
    TrainerApply: '/become-trainer/apply',
    // AreasOfSupport: '/request-support/areas-of-support',
    TypesOfSupport: '/request-support/types-of-support',
    HowCanSpiceHelpMyFacility: 'request-support/how-can-spice-help-my-facility',
    // TermsAndConditionsRequestSupport: '/request-support/terms-and-conditions',
    RequestSupportApply: '/request-support/apply',
    AboutSpice: '/about/about-spice',
    Governance: '/about/governance',
    Secretariat: '/about/secretariat',
    ContactUs: '/about/contact-us'
};